import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIList from "../../../../../api";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import Comments from "../../../../common/comments";
import GoalsForm from "../../goalsForm";

const ViewGoal = () => {

    const history = useHistory();
    const location = useLocation();

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const [commentLoader, setCommentLoader] = useState(false);
    const [allComments, setAllComments] = useState([]);
    const [addCommentLoader, setAddCommentLoader] = useState(false);
    const [comment, setComment] = useState("");

    useEffect(() => {  
        
            getAllComments();
        
    }, [])

console.log("allComments", allComments)
    const getAllComments = () => {

        let temp = structuredClone(allComments);

            setCommentLoader(true);
            APIList.goalComments({id: location?.state?.goalId})
            .then((res) => {
    
                // if(res?.data?.length > 0) {
                    temp.push({
                        id: location?.state?.goalId,
                        comments: res?.data
                    })
                // }
    
                setAllComments(temp);
    
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.message};
                    </div>
                )
            })
            .finally(() => {
                setCommentLoader(false);
            })
        
    }

    const getAllCommentsAfterSave = () => {
        setCommentLoader(true);
        APIList.goalComments({id: location?.state?.goalId})
        .then((res) => {

            let temp = structuredClone(allComments);

                let currentGoal = temp?.filter(x => x?.id === location?.state?.goalId)[0];
                currentGoal["comments"] = res?.data;

            setAllComments(temp);

        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.message};
                </div>
            )
        })
        .finally(() => {
            setCommentLoader(false);
        })
    }

    const addComment = (comment) => {
        setAddCommentLoader(true);
        APIList.addCommentOnGoal({
            goalEmployeeId: location?.state?.goalId,
            comment:comment,
            commentByEmployeeId: employeeDetails?.id
        })
        .then((res) => {
            getAllCommentsAfterSave();
            setComment("");
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.message};
                </div>
            )
        })
        .finally(() => {
            setAddCommentLoader(false);
        })
    }

    return (
        <div className="emp-goals-page">  

            {/*----- Edit & Comment Layout -----*/}
            <div className="outer-edit-comments-layout">
                {/*----- Edit Layout -----*/}
                <div className="edit-card-layout">
                   
                   <GoalsForm formId={location?.state?.goalId} employeeId={location?.state?.employeeId} pageMoodViewOrEdit={'view'} />

                </div>
                 
                 {
                    
                    commentLoader ? 
                    <div className="comments-card-layout">
                        <div className="d-flex">
                            <p className="comments-label">Comments</p>
                            <p className="comment-count">{allComments?.length}</p>
                        </div>
                        <Skeleton animation="wave" variant="rectangular" style={{height:"100px", marginBottom:"1rem"}} />
                        <Skeleton animation="wave" variant="rectangular" style={{height:"100px", marginBottom:"1rem"}} />
                        <Skeleton animation="wave" variant="rectangular" style={{height:"100px", marginBottom:"1rem"}} />
                    </div>
                    :                    
                    <Comments data={allComments?.filter(x => x?.id === location?.state?.goalId)[0]?.comments || []} addComment={addComment} id={location?.state?.goalId} addCommentLoader={addCommentLoader} comment={comment} setComment={setComment} />
                 }                 
               
            </div>
        </div>
    )
}

export default ViewGoal;