import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.GOALCYCLE_DATA_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.GOALCYCLE_DATA_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        goalCycle: payload || [],
    }),
    [types.ANNUALCYCLE_DATA_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),  
};

export default handleActions(actionHandler, {
    loader: false,
    goalCycle: [],   
});
